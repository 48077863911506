import { createRouter, createWebHistory } from 'vue-router'

import Machine from "@/views/Machine";
import Trade from '@/views/Trade.vue'
import Statistics from "@/views/Statistics.vue";

const routes = [
  {
    path: '/:year/:week/:day/:machine',
    alias: '/',
    component: Machine
  },
  {
    path: '/trade/:year/:week/:day',
    component: Trade
  },
  {
    path: '/trade',
    component: Trade
  },
  {
    path: '/stat',
    component: Statistics
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
