<template>
	<Bar
		id="chart-WeeklyTotalDailyEuroSum"
		:options="chartOptions"
		:data="chartData"
		:key="forceKey"
	/>
</template>

<script>

import {onMounted, ref, watch} from 'vue'

import { Bar }                                                                                                        from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors, LogarithmicScale } from 'chart.js'
import ChartDataLabels                                                                                                from 'chartjs-plugin-datalabels'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors, LogarithmicScale, ChartDataLabels)

export default {
	props: {
		values: {
			type: Object,
			default: []
		},
		max: {
			type: [String, Number, null],
			default: null
		}
	},
	name: "WeeklyTotalDailyEuroSum",
	components: { Bar },
	setup(props, context) {
		const sumsOnTop = ref([])
		const forceKey = ref(0)
		const chartData = ref({
			datasets: []
		})

		const chartOptions = ref({
			responsive: true,
			maintainAspectRatio: false,
/*
			scales: {
				x: {
					stacked: true
				},
				y: {
					stacked: true,
					min: 0,
					max: null
				}
			},
*/
			plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = context.dataset.label || ''
              
              if (label) {
                label += ': '
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'EUR' }).format(context.parsed.y)
              }
              return label
            },
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            borderRadius: 10,
            usePointStyle: true,
            pointStyle: 'rectRounded',
          },
        },
        datalabels: {
          formatter: function(value, context) {
            // return context.datasetIndex === context.chart.data.datasets.length - 1 && sumsOnTop.value[context.dataIndex + 1] !== 0 ? sumsOnTop.value[context.dataIndex + 1].toLocaleString() + ' €' : ''
            return ''
          },
          anchor: 'end',
          align: 'end',
          rotation: -80,
          offset: 10
        }
			}
		})

		onMounted(() => {

		})

		watch(() => props.values, () => {
			chartData.value.datasets = props.values




			let total = Array(7).fill(0)

			props.values.forEach((dataset) => {
				for (let [key, value] of Object.entries(dataset.data)) {
					let day

					switch (key) {
						case 'Hétfő': day = 0; break;
						case 'Kedd': day = 1; break;
						case 'Szerda': day = 2; break;
						case 'Csütörtök': day = 3; break;
						case 'Péntek': day = 4; break;
						case 'Szombat': day = 5; break;
						case 'Vasárnap': day = 6; break;
					}

					total[day] += value

				}
			})

			sumsOnTop.value = total

			forceKey.value++;
		})

		watch(() => props.max, () => {
			chartOptions.value.scales.y.suggestedMax = parseInt(props.max)
			forceKey.value++;
		})


		return {
			chartData,
			chartOptions,
			forceKey
		}
	}
}
</script>
