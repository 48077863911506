<template>
	<div class="col-12 float-start clearfix machine-view trade-view">

		<div class="listening high-z-index " :class="listening ? 'listening-visible' : 'listening-hidden'">
			<div class="numpad"></div>
			<div class="keys">
				<div class="key morning-shift">
					<div>+</div><div>06:00 - 14:00</div>
				</div>
			</div>
		</div>

		<div class="col-2 float-start clearfix left-panel">

		</div>
		<div class="col-8 float-start clearfix center-panel">
      <div class="logo"></div>
      <div class="trade-title">
        trade<br>
        <span class="trade-total">total: €{{ yearlyTotal }}</span>
      </div>

			<!--<div class="col-12 float-start-clearfix day-container">
				<div class="day-name" :class="activeDay(1)" @click="setCurrentDay(1)">Hétfő</div>
				<div class="day-name" :class="activeDay(2)" @click="setCurrentDay(2)">Kedd</div>
				<div class="day-name" :class="activeDay(3)" @click="setCurrentDay(3)">Szerda</div>
				<div class="day-name" :class="activeDay(4)" @click="setCurrentDay(4)">Csütörtök</div>
				<div class="day-name" :class="activeDay(5)" @click="setCurrentDay(5)">Péntek</div>
				<div class="day-name" :class="activeDay(6)" @click="setCurrentDay(6)">Szombat</div>
				<div class="day-name" :class="activeDay(7)" @click="setCurrentDay(7)">Vasárnap</div>
			</div>-->

			<div class="col-12 float-start clearfix shifts-container" style="margin-top: 5rem;">

				<div class="col-12 float-start clearfix shift">

					<div class="float-start clearfix shift-column shift-column-header" v-for="header in recordHeader">{{ header }}</div>
					<div class="col-12 float-start clearfix shift-row" v-for="(record, index, key) in records">
						<div class="order-actions">
							<div class="float-start save-button" @click="hideTab(record, index, true)">Mentés</div>
							<div class="float-start del-button" @click="hideTab(record, index)">Mégse</div>
						</div>

						<div class="delete-order" v-if="record.ID != null">
							<div class="float-start del-button" @click="delOrder(record, index)">Törlés</div>
						</div>

						<div class="float-start clearfix shift-column text-start"><input v-model="record.product" :disabled="record.ID !== null"></div>

						<div class="float-start clearfix shift-column shift-column-header p-0" style="padding-top: 6px !important;">
							<multiselect :options="suppliers"
							             v-model="record.supplier"
                           :can-clear="false"
                           :can-deselect="false"
                           :create-option="true"
                           :add-option-on="['enter', 'tab']"
                           placeholder="Beszállító"
                           @keyup.tab.native
                           :searchable="true"
                           :disabled="record.ID !== null"
                           :caret="record.ID === null"
							/>
						</div>
						<div class="float-start clearfix shift-column shift-column-header p-0" style="padding-top: 6px !important;">
              <multiselect :options="partners"
                           v-model="record.partner"
                           :can-clear="false"
                           :can-deselect="false"
                           :create-option="true"
                           :add-option-on="['enter', 'tab']"
                           placeholder="Vevő"
                           @keyup.tab.native
                           :searchable="true"
                           :disabled="record.ID !== null"
                           :caret="record.ID === null"
              />
						</div>

						<div class="float-start clearfix shift-column"><input @change="calculateGP(record)" v-model="record.invoice" :disabled="record.ID !== null"></div>
            <div class="float-start clearfix shift-column"><input @change="calculateGP(record)" v-model="record.price" type="number" min="0" :disabled="record.ID !== null"></div>

						<div class="float-start clearfix shift-column"><input type="date" v-model="record.date" :disabled="record.ID !== null"></div>
						<div class="float-start clearfix shift-column"><input v-model="record.remark" :disabled="record.ID !== null"></div>

					</div>

					<img class="add-order" :src="require('@/assets/image/add.png')" @click="addOrder" v-if="!emptyElementPresent">
				</div>
			</div>
		</div>
		<div class="col-2 float-start clearfix right-panel">

			<div class="week-no">{{ currentWeek }}.</div>
			<div class="datepicker">
				<input class="float-start" type="date" v-model="currentDate" @change="setCurrentDay(null)" tabindex="-1">
				<div class="datepicker-height-adjust float-start">I</div>
			</div>
		</div>
	</div>
</template>

<script>
// TODO Sanyi gépeinek a neve
// TODO Napi nézethez napi szum eur
// TODO Napi műszakonkénti szum eur

import {axios} from "@/main";

import {useToast} from 'vue-toastification'

import {useRoute, useRouter}             from "vue-router";
import {watch, ref, nextTick, onMounted, toRefs, computed} from "vue";

import {DateTime}          from "luxon";
import StatusIcon            from "@/components/StatusIcon";
import Multiselect           from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
	name: "Trade",
	components: { StatusIcon, Multiselect },
	setup(props, context) {
		const toast = useToast();
		const listening = ref(null)
		const emptyElementPresent = ref(false)

		/** Routing and day/date selection **/
		const currentDate = ref(DateTime.now().toFormat('yyyy-MM-dd'))

		const currentYear = ref(DateTime.local(currentDate).year)
		const currentWeek = ref(DateTime.local(currentDate).weekNumber)
		const currentDay = ref(DateTime.local(currentDate).weekday)

		const route = useRoute()
		const router = useRouter()

		const activeDay = (day) => {
			return day === currentDay.value ? 'day-name-active' : ''
		}
		const setCurrentDay = (day) => {
			const date = currentDate.value.split('-')

			currentYear.value = day === null ? parseInt(date[0]) : currentYear.value
			currentWeek.value = day === null ? DateTime.local(parseInt(date[0]), parseInt(date[1]), parseInt(date[2])).weekNumber : currentWeek.value
			currentDay.value =  day === null ? DateTime.local(parseInt(date[0]), parseInt(date[1]), parseInt(date[2])).weekday : day

			updateRoute()
		}

		const updateRoute = () => {
			router.push(`/trade/${currentYear.value}/${currentWeek.value}/${currentDay.value}`).then(() => {
				getRecords()
				toggleHideButton(false)
			})
		}


		/** Shifts and their data **/

    onMounted(() => {
      getYearlyTotal()
      updateRoute()
      getPartners()
      getSuppliers()
      getRecords()
    })

    const yearlyTotal = ref(0)

    const partners = ref([])
    const suppliers = ref([])

		const storeTemplate = {
      ID: null,
			tradeId: null,
			supplier: null,
      partner: null,
      price: null,
      invoice: null,
      date: null,
			remark: "",
		}

		const records = ref([])

		const recordHeader = ['Termék', 'Beszállító', 'Vevő', 'Beszerzési érték', 'Eladási ár', 'Dátum', 'GP%']

		const toggleHideButton = (state) => {
			emptyElementPresent.value = state
		}

		const addOrder = () => {
			toggleHideButton(true)

			records.value.push(JSON.parse(JSON.stringify(storeTemplate)))

			setTimeout(() => {
				document.querySelector(`.shift > .shift-row:last-of-type > .order-actions`).classList.add('order-actions-active')
			}, 500)
			setTimeout(() => {
				document.querySelector(`.shift > .shift-row:last-of-type > .order-actions`).classList.add('high-z-index')
			}, 1000)
		}

		const delOrder = (record, index) => {
			if (confirm('Biztosan törölni szeretnéd ezt a sort?')) {
				axios({ ID: record.ID }, '/api/trade.php?delete-record').then(() => {
					records.value.splice(index, 1)
          getYearlyTotal()
				})
			}
		}

		const hideTab = (record, index, save = false) => {
			let error = false

			if (save === true) {
				Object.keys(record).forEach((key) => {
					if (route.params.week == null || route.params.day == null || route.params.year == null) return error = true
					if (record[key] == null && !['ID', 'tradeId'].includes(key)) return error = true
				})

				if (error !== true) {
          if (route.params.week == null || route.params.day == null || route.params.year == null) return error = true
					document.querySelector(`.shift > .shift-row:last-of-type > .order-actions`).classList.remove('high-z-index')
					setTimeout(() => {
						document.querySelector(`.shift > .shift-row:last-of-type > .order-actions`).classList.remove('order-actions-active')
					}, 1)

          axios({record: record, params: route.params}, '/api/trade.php?new-record').then((r) => {
            record.ID = r.data
            record.tradeId = r.data.padStart(5, '0')

            getYearlyTotal()
          })

				} else {
          console.log(record)
					toast.warning("Nincs minden mező kitöltve", {
						position: "top-left",
						timeout: 5000,
						closeOnClick: true,
						pauseOnHover: true,
					});
				}
			} else {
				records.value.splice(index, 1)
			}

			if (error === false) {
				toggleHideButton(false)
			}
		}

    const getRecords = () => {

      if (route.params.week == null || route.params.day == null || route.params.year == null) return

      axios(route.params, '/api/trade.php?get-records-on-date').then((r) => {
        records.value = r.data
      })
    }

    const getPartners = () => {
      axios({}, 'api/partner.php?get').then((r) => {
        partners.value = r.data
      })
    }

    const getSuppliers = () => {
      axios({}, 'api/supplier.php?get').then((r) => {
        suppliers.value = r.data
      })
    }

    const getYearlyTotal = () => {
      axios({}, 'api/trade.php?yearly-total').then((r) => {
        return yearlyTotal.value = r.data
      })
    }

		const calculateGP = (row) => {

			if (!row.price || !row.invoice) {
				row.remark = ''
				return
			}

			row.remark = ((row.price / row.invoice * 100) - 100).toFixed(4)
		}

		return {
      yearlyTotal,

			listening,
			emptyElementPresent,

			activeDay,
			setCurrentDay,
			currentDate,
			currentWeek,

      getRecords,
			records,
			recordHeader,

      partners,
      suppliers,

			addOrder,
			delOrder,

			calculateGP,

			hideTab,
		}
	}
}
</script>

<style lang="scss">

.machine-view.trade-view {

  .Vue-Toastification__toast--warning {
    background-color: #CC9A05 !important;
  }

  .multiselect-search,
  .multiselect {
    width: 86% !important;
    background: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .multiselect.is-disabled {
    margin: 0 !important;
  }

  .multiselect.is-disabled .multiselect-wrapper {
    cursor: default;
  }

  .multiselect-single-label {
    width: 200% !important;
    max-width: 200% !important;
  }

  .daily-machine-sum {
    text-align: right;
    top: -24px;
    width: 200px;
    left: -270px;
    font-size: 24px;
  }

  .multiselect-placeholder {
    text-align: left;
    padding: 0;
  }

  .center-panel .shifts-container .shift .shift-column:not(:first-of-type) {
    width: calc(calc(100% - 300px) / 6) !important;
  }

  .center-panel .shifts-container .shift .shift-column.text-start,
  .center-panel .shifts-container .shift .shift-column:first-of-type {
    width: 300px !important;
  }

}

</style>
