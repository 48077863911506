<template>
	<multiselect :options="machines" v-model="currentMachineNumber" @change="setCurrentMachine" placeholder="Gép választás" :can-clear="false" :can-deselect="false"/>
	<div class="col-12 float-start clearfix machine-view">

		<div class="listening high-z-index " :class="listening ? 'listening-visible' : 'listening-hidden'">
			<div class="numpad"></div>
			<div class="keys">
				<div class="key morning-shift">
					<div>+</div><div>06:00 - 14:00</div>
				</div>
				<div class="key evening-shift">
					<div>+</div><div>14:00 - 22:00</div>
				</div>
				<div class="key night-shift">
					<div>+</div><div>22:00 - 06:00</div>
				</div>
			</div>
		</div>

		<div class="col-2 float-start clearfix left-panel">
			<div class="machine-number">{{ currentMachineNumber }}</div>
		</div>
		<div class="col-8 float-start clearfix center-panel">
			<div class="col-12 float-start clearfix machine-name">
				{{ currentMachineName }}
			</div>
			<div class="col-12 float-start-clearfix day-container">
				<div class="day-name" :class="activeDay(1)" @click="setCurrentDay(1)">Hétfő</div>
				<div class="day-name" :class="activeDay(2)" @click="setCurrentDay(2)">Kedd</div>
				<div class="day-name" :class="activeDay(3)" @click="setCurrentDay(3)">Szerda</div>
				<div class="day-name" :class="activeDay(4)" @click="setCurrentDay(4)">Csütörtök</div>
				<div class="day-name" :class="activeDay(5)" @click="setCurrentDay(5)">Péntek</div>
				<div class="day-name" :class="activeDay(6)" @click="setCurrentDay(6)">Szombat</div>
				<div class="day-name" :class="activeDay(7)" @click="setCurrentDay(7)">Vasárnap</div>
			</div>

			<div class="col-12 float-start clearfix shifts-container">
				{{ dailyTotal() }}
				<div class="col-12 float-start clearfix shift" v-for="(shift, shiftKey, index) in shifts">
					<span style="position: absolute; width: 44px;">
						<img :src="require(`@/assets/image/key-${index + 1}-only.png`)" style="position: absolute; width: 44px; left: -54px;">
						<div class="daily-machine-sum">
							{{ shiftTotal(shiftKey)[0].toLocaleString() }} db <br>
							{{ shiftTotal(shiftKey)[1].toLocaleString() }} €
						</div>
					</span>

					<div class="float-start clearfix shift-column shift-column-header" v-for="header in shiftHeader">{{ header }}</div>
					<div class="col-12 float-start clearfix shift-row" v-for="(order, orderKey) in shift">
						<span class="order-actions">
							<div class="float-start save-button" @click="hideTab(shift, shiftKey, order, orderKey, true)">Mentés</div>
							<div class="float-start del-button" @click="hideTab(shift, shiftKey, order, orderKey)">Mégse</div>
						</span>
						<span class="delete-order" v-if="order.recordId != null">
							<div class="float-start del-button" @click="delOrder(shift, shiftKey, order, orderKey)">Törlés</div>
						</span>

						<div class="float-start clearfix shift-column shift-column-header p-0" style="padding-top: 6px !important;">
							<multiselect :options="orderStatus(orderNumbers, 0)" v-if="order.recordId == null"
							             v-model="order.order"
							             :can-clear="false"
							             :can-deselect="false"
							             :disabled="order.orderId != null"
							             :caret="order.orderId == null"
							             :searchable="true"
							             :create-option="true"
							             :add-option-on="['enter', 'tab']"
							             placeholder="Order#"
							             @change="updateRowValues(order)"
							             @keyup.tab.native
							/>
							<multiselect :options="orderNumbers" v-else
							             v-model="order.orderId"
							             :can-clear="false"
							             :can-deselect="false"
							             :caret="order.orderId == null"
							             disabled
							/>
						</div>

						<div class="float-start clearfix shift-column">
              <input v-model="order.grind" type="checkbox" :disabled="order.orderId != null" :data-input-focus="order.orderId == null ? 'focus-me' : false">
            </div>
						<div class="float-start clearfix shift-column">
              <input v-model="order.coat" type="checkbox" :disabled="order.orderId != null">
            </div>
						<div class="float-start clearfix shift-column">
              <input v-model="order.quantity" :disabled="order.orderId != null" type="number" min="0">
            </div>
						<div class="float-start clearfix shift-column">
              <input v-model="order.price" :disabled="order.orderId != null" type="number" min="0">
            </div>

						<div class="float-start clearfix shift-column">
              <status-icon :type="order.status === 0 ? 'processing' : 'completed'"/>
            </div>

						<div class="float-start clearfix shift-column">
              <input v-model="order.remark" :disabled="order.recordId != null">
            </div>

						<div class="float-start clearfix shift-column" v-if="!('maxLeft' in order)">
							<input
								v-model="order.finished"
								:disabled="order.recordId != null"
								type="number"
								min="0"
								@keydown.tab.enter="hideTab(shift, shiftKey, order, orderKey, true)"
							></div>
						<div class="float-start clearfix shift-column" v-if="('maxLeft' in order)">
							<input
								v-model="order.finished"
								:disabled="order.recordId != null"
								type="number"
								min="0"
								:max="parseInt(order.maxLeft) + 10"
								:placeholder="`Max.: ${order.maxLeft}`"
								@change="enforceMaxAttribute(order)"
								@keydown.tab.enter="hideTab(shift, shiftKey, order, orderKey, true)"
								:data-input-focus="order.recordId ? false : 'focus-me'"
							></div>
					</div>

					<img class="add-order" :src="require('@/assets/image/add.png')" @click="addOrder(shiftKey)" v-if="!emptyElementPresent && machineIsSelected">
				</div>
			</div>
		</div>
		<div class="col-2 float-start clearfix right-panel">

			<div class="logo"></div>

			<div class="week-no">{{ currentWeek }}.</div>
			<div class="datepicker">
				<input class="float-start" type="date" v-model="currentDate" @change="setCurrentDay(null)" tabindex="-1">
				<div class="datepicker-height-adjust float-start">I</div>
			</div>

			<div class="machine-image" style="background-size: contain; background-repeat: no-repeat; background-position: center center" :style="`background-image: url(${currentMachineImage})`"></div>

		</div>
	</div>
</template>

<script>
// TODO Sanyi gépeinek a neve
// TODO Napi nézethez napi szum eur
// TODO Napi műszakonkénti szum eur

import {axios} from "@/main";

import {useToast} from 'vue-toastification'

import {useRoute, useRouter}             from "vue-router";
import {watch, ref, nextTick, onMounted, toRefs, computed} from "vue";

import {DateTime}          from "luxon";
import StatusIcon            from "@/components/StatusIcon";
import Multiselect           from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
	name: "Machine",
	components: { StatusIcon, Multiselect },
	setup(props, context) {
		const toast = useToast();
		const listening = ref(null)
		const timer = ref(null)
		const holdOnKey = ref(null)
		const emptyElementPresent = ref(false)


		onMounted(() => {
			getOrderNumbers()
			getMachines()

			const removeHoldOnKey = () => {
				listening.value = false
				holdOnKey.value = null
			}

			document.addEventListener(("keydown"), (e) => {
				if (e.key === 'Escape') {
					getDataOnDate()
					emptyElementPresent.value = false
				}
				if (emptyElementPresent.value === true) return false
				if (e.key === '+') {
					clearTimeout(timer.value)
					listening.value = true
					timer.value = setTimeout(removeHoldOnKey, 1000)
				}

				if (holdOnKey.value === '+' && !emptyElementPresent.value && machineIsSelected.value) {
					e.preventDefault()
					switch (e.key) {
						case '1': addOrder('day')
							break;
						case '2': addOrder('mid')
							break;
						case '3': addOrder('eve')
							break;
					}
				}

				holdOnKey.value = e.key
			})
		})

		/** Routing and day/date/machine selection **/
		const currentDate = ref(DateTime.now().toFormat('yyyy-MM-dd'))

		const currentYear = ref(DateTime.local(currentDate).year)
		const currentWeek = ref(DateTime.local(currentDate).weekNumber)
		const currentDay = ref(DateTime.local(currentDate).weekday)

		const currentMachineNumber = ref(null)
		const currentMachineName = ref(null)
		const currentMachineImage = ref(null)

		const route = useRoute()
		const router = useRouter()

		const machines = ref([])

		const activeDay = (day) => {
			return day === currentDay.value ? 'day-name-active' : ''
		}
		const setCurrentDay = (day) => {
			const date = currentDate.value.split('-')

			currentYear.value = day === null ? parseInt(date[0]) : currentYear.value
			currentWeek.value = day === null ? DateTime.local(parseInt(date[0]), parseInt(date[1]), parseInt(date[2])).weekNumber : currentWeek.value
			currentDay.value =  day === null ? DateTime.local(parseInt(date[0]), parseInt(date[1]), parseInt(date[2])).weekday : day

			updateRoute()
		}

		const setCurrentMachine = () => {
			nextTick(() => {
				currentMachineName.value = machines.value.find((x) => { return x.value === parseInt(currentMachineNumber.value) }).label
				currentMachineImage.value = machines.value.find((x) => { return x.value === parseInt(currentMachineNumber.value) }).image
				updateRoute()
			})
		}

		const updateRoute = () => {
			router.push(`/${currentYear.value}/${currentWeek.value}/${currentDay.value}/${currentMachineNumber.value}`).then(() => {
				getDataOnDate()
				toggleHideButton(false)
			})
		}


		/** Shifts and their data **/

		const orderNumbers = ref([])

		const orderTemplate = {
			orderId: null,
      grind: false,
      coat: false,
			recordId: null,
			order: null,
			// desc: null,
			// item: null,
			quantity: null,
			price: null,
			remark: "",
			status: 0,
			finished: 0,
			shift: null,
		}

		const shifts = ref({
			day: [],
			mid: [],
			eve: []
		})

		// TODO: Item -> Coating (Igen/Nem)
		// TODO: Desc -> (Élezés/Gyártás)
		const shiftHeader = ['Order', 'Grind', 'Coat', 'Qty', 'Price', 'Status', 'Remark', 'Finished']

		const getOrderNumbers = () => {
			axios({}, '/api/order.php?get-unfinished-orders').then((r) => {
				orderNumbers.value = r.data
			})
		}

		const getMachines = () => {
			axios({}, '/api/machine.php?get-machines').then((r) => {
				machines.value = r.data

				if (route.params.machine != null) {
					currentMachineNumber.value = route.params.machine
					setCurrentMachine()
				}

			})
		}

		const updateRowValues = (order) => {
			nextTick(() => {
				const selected = orderNumbers.value.find((x) => {
					return x.value === order.order
				})

				if (selected != null) {
					order.maxLeft = selected.maxLeft
					order.orderId = selected.orderId
					// order.desc = selected.desc
					// order.item = selected.item
					order.grind = !!selected.grind
					order.coat = !!selected.coat
					order.price = selected.price
					order.quantity = selected.quantity
					order.finished = null
				}

				nextTick(() => {
					document.querySelectorAll('[data-input-focus="focus-me"]')[0].focus()
				})
			})
		}

		const enforceMaxAttribute = (order, leeway = 10) => {
			if (order.finished != null && order.finished.toString().match(/^\d+$/g).length === 0) return order.finished = null
			if (parseInt(order.finished) > parseInt(order.maxLeft) + leeway) return order.finished = parseInt(order.maxLeft) + leeway
		}

		const getDataOnDate = () => {
			axios(route.params, '/api/order.php?get-records-on-date').then((r) => {
        console.log(r.data)
        for (const [shiftKey, shift] of Object.entries(r.data)) {
          shift.forEach((row, index) => {
            r.data[shiftKey][index].coat = !!row.coat
            r.data[shiftKey][index].grind = !!row.grind
          })
        }
        
        shifts.value = r.data
			})
		}

		const toggleHideButton = (state) => {
			emptyElementPresent.value = state
		}

		const addOrder = (shiftKey) => {

			toggleHideButton(true)

			shifts.value[shiftKey].push(JSON.parse(JSON.stringify(orderTemplate)))

			let key
			switch (shiftKey) {
				case 'day': key = 1
							break;
				case 'mid': key = 2
							break;
				case 'eve': key = 3
			}

			shifts.value[shiftKey][shifts.value[shiftKey].length - 1].shift = key

			setTimeout(() => {
				document.querySelector(`.shift:nth-child(${key}) > .shift-row:last-of-type > .order-actions`).classList.add('order-actions-active')
			}, 500)
			setTimeout(() => {
				document.querySelector(`.shift:nth-child(${key}) > .shift-row:last-of-type > .order-actions`).classList.add('high-z-index')
			}, 1000)

			nextTick(() => {
				document.querySelectorAll('.multiselect-search')[0].focus()
			})
      
      console.log(shifts.value[shiftKey])
		}
		const delOrder = (shift, shiftKey, order, orderKey) => {
			if (confirm('Biztosan törölni szeretnéd ezt a sort?')) {
				axios({id: order.recordId}, '/api/order.php?delete-record').then(() => {
					shift.splice(orderKey, 1)
				})
			}
		}

		const hideTab = (shift, shiftKey, order, orderKey, save = false) => {
			let error = false

			if (save === true) {
				Object.keys(order).forEach((key) => {
					if (route.params.machine == null || route.params.week == null || route.params.day == null || route.params.year == null) return error = true
					if (((order[key] == null)) && !(['orderId', 'recordId', 'grind', 'coat'].includes(key))) return error = true
				})

				if (error !== true) {

					let key
					switch (shiftKey) {
						case 'day': key = 1
							break;
						case 'mid': key = 2
							break;
						case 'eve': key = 3
					}

					document.querySelector(`.shift:nth-child(${key}) > .shift-row:last-of-type > .order-actions`).classList.remove('high-z-index')
					setTimeout(() => {
						document.querySelector(`.shift:nth-child(${key}) > .shift-row:last-of-type > .order-actions`).classList.remove('order-actions-active')
					}, 1)

          console.log(order)
					let urlType = order.orderId ? 'update' : 'new'
					axios(order, `/api/order.php?${urlType}-order`).then((r) => {
						if (urlType === 'new') order.orderId = r.data

						axios({order: order, params: route.params}, '/api/order.php?new-record').then((record) => {
							order.recordId = record.data.id
							order.status = record.data.status
							getOrderNumbers()
						})
					})

				} else {
					toast.warning("Nincs minden mező kitöltve", {
						position: "top-left",
						timeout: 5000,
						closeOnClick: true,
						pauseOnHover: true,
					});
				}
			} else {
				shift.splice(orderKey, 1)
			}

			if (error === false) {
				toggleHideButton(false)
			}
		}

		const truncateTables = () => {
			if (confirm('Biztos üríteni akarod a táblákat?')) {
				axios({}, '/api/order.php?truncate').then((r) => {
					router.go()
				})
			}
		}

		const orderStatus = (orders, status) => {
			return orders.filter((x) => { return x.status === status })
		}

		const shiftTotal = (shiftKey) => {
			let finished = 0
			let sum = 0

			shifts.value[shiftKey].forEach((el) => {
				if (el.finished != null && el.price != null) {
					sum += parseFloat(el.price) * el.finished
					finished += el.finished
				}
			})

			return [finished, sum]
		}

		const dailyTotal = () => {
			let total = 0

			for (const [key, shift] of Object.entries(shifts.value)) {
				shift.forEach((record) => {
					if (record.finished != null && record.price != null) {
						total += parseInt(record.finished) * parseFloat(record.price)
					}
				})
			}

			return `Teljes napi bevétel: ${total.toLocaleString()} €`
		}

		const machineIsSelected = computed(() => {
			return route.params.machine != null
		})

		return {
			truncateTables,

			listening,
			emptyElementPresent,

			orderNumbers,

			activeDay,
			setCurrentDay,
			currentDate,
			currentWeek,

			machines,
			currentMachineName,
			currentMachineNumber,
			currentMachineImage,
			setCurrentMachine,
			machineIsSelected,

			shifts,
			shiftHeader,
			shiftTotal,
			dailyTotal,

			addOrder,
			delOrder,
			updateRowValues,
			enforceMaxAttribute,
			orderStatus,

			hideTab,
		}
	}
}
</script>

<style lang="scss">
.machine-view {
  
  .Vue-Toastification__toast--warning {
    background-color: #CC9A05 !important;
  }
  
  .multiselect-search,
  .multiselect {
    width: 86% !important;
    background: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  
  .multiselect.is-disabled {
    margin: 0 !important;
  }
  
  .multiselect.is-disabled .multiselect-wrapper {
    cursor: default;
  }
  
  .multiselect-single-label {
    width: 200% !important;
    max-width: 200% !important;
  }
  
  .daily-machine-sum {
    text-align: right;
    top: -24px;
    width: 200px;
    left: -270px;
    font-size: 24px;
  }
  
  .shift-column {
    width: calc(calc(100% - 810px) / 1);
  }
  
  .shift-column-header:first-of-type,
  .center-panel .shifts-container .shift .shift-column:first-of-type {
    width: 130px !important;
  }
  
  .shift-column-header:nth-of-type(2),
  .shift-column-header:nth-of-type(3),
  .center-panel .shifts-container .shift .shift-column:nth-of-type(2),
  .center-panel .shifts-container .shift .shift-column:nth-of-type(3) {
    width: 74px !important;
    text-align: center;
  }
  
  .shift-column-header:nth-of-type(4),
  .shift-column-header:nth-of-type(5),
  .shift-column-header:nth-of-type(8),
  .center-panel .shifts-container .shift .shift-column:nth-of-type(4),
  .center-panel .shifts-container .shift .shift-column:nth-of-type(5),
  .center-panel .shifts-container .shift .shift-column:nth-of-type(8) {
    width: 150px !important;
    text-align: center;
  }

  .shift-column-header:nth-of-type(6),
  .center-panel .shifts-container .shift .shift-column:nth-of-type(6) {
    width: 82px !important;
    text-align: center;
  }


}
</style>
