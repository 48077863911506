<template>
	<div class="status-icon" v-html="statusText"></div>
</template>

<script>
import {onMounted, ref, watch} from "vue";

export default {
	props: {
		type: {
			type: String,
			default: null
		}
	},
	name: "StatusIcon",
	setup(props, context) {

		const backgroundColor = ref(null)
		const fontColor = ref(null)

		// const text = ['Completed&nbsp;✓', 'Processing&nbsp;↻']
		const text = ['✓', '↻']
		const statusText = ref(null)

		onMounted(() => {
			setText()
		})

		watch(() => props.type, () => {
			setText()
		})

		const setText = () => {
			switch (props.type) {
				case 'completed':
					statusText.value = text[0]
					backgroundColor.value = 'var(--iscar-background-green)'
					fontColor.value = 'var(--iscar-font-green)'
					break;
				case 'processing':
					statusText.value = text[1]
					backgroundColor.value = 'var(--iscar-background-blue)'
					fontColor.value = 'var(--iscar-font-blue)'
					break;
			}
		}

		return {
			statusText,
			backgroundColor,
			fontColor
		}
	}
}
</script>

<style scoped>
	.status-icon {
    width: 24px;
		height: 24px;
		text-align: center;

		background: v-bind(backgroundColor);
		color: v-bind(fontColor);
		border-radius: 12px;

		font-size: 12px;
		padding-top: 4px;
    
    display: inline-block;
	}
</style>
