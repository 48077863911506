<template>
	<Bar
		id="chart-YearSumToThisDay"
		:options="chartOptions"
		:data="chartData"
		:key="forceKey"
	/>
</template>

<script>

import {onMounted, ref, watch} from 'vue'

import { Bar }                                                                                                        from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors, LogarithmicScale } from 'chart.js'
import ChartDataLabels                                                                                                from 'chartjs-plugin-datalabels'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors, LogarithmicScale, ChartDataLabels)

export default {
	props: {
		values: {
			type: Object,
			default: []
		},
		max: {
			type: [String, Number, null],
			default: null
		}
	},
	name: "YearSumToThisDay",
	components: { Bar },
	setup(props, context) {
		const sumsOnTop = ref([])
		const forceKey = ref(0)
		const chartData = ref({
			labels: [],
			datasets: []
		})

		const chartOptions = ref({
			indexAxis: 'y',
			responsive: true,
			maintainAspectRatio: false,
			scales: {
			},
			plugins: {
				legend: false,
				datalabels: {
					formatter: function(value, context) {
						return context.chart.data.datasets[0].data[context.dataIndex] + (context.dataIndex === 0 ? ' db' : ' €')
					},
					anchor: 'end',
					align: 'end'
				}
			}
		})

		watch(() => props.values, () => {
			chartData.value.labels = props.values.labels
			chartData.value.datasets = props.values.datasets

			forceKey.value++;
		})

/*
		watch(() => props.max, () => {
			chartOptions.value.scales.y.suggestedMax = parseInt(props.max)
			forceKey.value++;
		})
*/

		return {
			chartData,
			chartOptions,
			forceKey
		}
	}
}
</script>
