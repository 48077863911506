<template>
	<Bar
		id="chart-MonthlyTotalDailyEuroSum"
		:options="chartOptions"
		:data="chartData"
		:key="forceKey"
	/>
</template>

<script>

import {onMounted, ref, watch, nextTick} from 'vue'
import {DateTime}              from "luxon";

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors, LogarithmicScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors, LogarithmicScale)
export default {
	props: {
		values: {
			type: Object,
			default: []
		}
	},
	name: "YearlyTotalMachineMonthlyEuroSum",
	components: { Bar },
	setup(props, context) {
		const sumsOnTop = ref([])
		const forceKey = ref(0)
		const labels = ref([])

		const chartData = ref({
			labels: [],
			datasets: []
		})

		const chartOptions = ref({
			responsive: true,
			maintainAspectRatio: false,
/*
			scales: {
				x: {
					stacked: true
				},
				y: {
					stacked: true,
					min: 0,
					suggestedMax: 15000
				}
			},
*/
			plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = context.dataset.label || ''
  
              if (label) {
                label += ': '
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'EUR' }).format(context.parsed.y)
              }
              return label
            },
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 20,
            boxHeight: 20,
            borderRadius: 10,
            usePointStyle: true,
            pointStyle: 'rectRounded',
          },
        },
				datalabels: {
					formatter: function(value, context) {
            // console.log(sumsOnTop.value, context.datasetIndex, context.dataIndex)
            
            if (!sumsOnTop.value[context.dataIndex + 1][context.datasetIndex] || sumsOnTop.value[context.dataIndex + 1][context.datasetIndex] <= 0) {
              return ''
            }
            
						return sumsOnTop.value[context.dataIndex + 1][context.datasetIndex].toLocaleString() + ' €'
					},
					anchor: 'end',
					align: 'end',
					rotation: 0,
					offset: -5
				}
			}

		})

		onMounted(() => {
			const tempLabels = []
			const weeks = DateTime.local(DateTime.now().year).weeksInWeekYear

			const currentDate = DateTime.now()
			const dateHalf = DateTime.fromISO(`${DateTime.now().year}-07-01`)
			const fromWeek = DateTime.fromISO(`${DateTime.now().year}-07-01`).weekNumber + 1

			// if (currentDate < dateHalf) {
			// 	for(let i = 1; i < fromWeek; i++) {
			// 		tempLabels.push(i.toString())
			// 	}
			// } else {
			// 	for(let i = fromWeek; i <= weeks; i++) {
			// 		tempLabels.push(i.toString())
			// 	}
			// }
      //
			// labels.value = tempLabels

		})

		watch(labels, () => {
			chartData.value.labels = labels.value
			forceKey.value++;
		})

		watch(() => props.values, () => {
      console.log('ok', props.values)
			chartData.value.datasets = props.values
      
      sumsOnTop.value.push([])
      for (let i = 1; i <= 12; i++) {
        sumsOnTop.value.push([props.values[0].data[i], props.values[1].data[i]])
      }
      
      // for (let [datasetIndex, dataset] of Object.entries(props.values)) {
      //   for (let [dataIndex, value] of Object.entries(dataset.data)) {
      //     const arr = [0,0]
      //     sumsOnTop.value[dataIndex][datasetIndex] = value
      //   }
      // }
      
      nextTick(() => {
        console.log(sumsOnTop.value)
			  forceKey.value++;
      })
		}, { flush: 'post' })

		return {
			chartData,
			chartOptions,
			forceKey
		}
	}
}
</script>
