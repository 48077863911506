<template>
  <div class="daily-indicator d-flex flex-full">
    <div class="mx-auto" style="display: flex; flex-grow: 1; height: 100px; max-width: 50%;">
      <div class="rod" :class="{ 'longer' : [1, 6, 11, 16, 21].includes(i) }" v-for="i in 21">
        <span v-if="i === 1">{{ 0 }}</span>
        <span v-if="i === 6">{{ 50 }}</span>
        <span v-if="i === 11">{{ 100 }}</span>
        <span v-if="i === 16">{{ 150 }}</span>
        <span v-if="i === 21">{{ 200 }}</span>
      </div>
      
      <span class="rod pointer" :style="`left: calc(calc(100% / 21) * ${(20/200) * getDeviation});`"/>
    </div>
    <br>
    <div style="position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); font-size: 20px;">{{ getDeviation.toFixed(2) }}%</div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'

export default {
  name: 'DailyIndicator',
  props: {
    current: {
      type: [Number, String],
    },
    limit: {
      type: [Number],
    }
  },
  setup(props, context) {
    const deviation = ref(100)
    
    const getDeviation = computed(() => {
      if (deviation.value > 200) return 200
      if (deviation.value < 0) return 0
      return deviation.value
    })
    
    
    
    watch(() => [props.current, props.limit], (c) => {
      if (c?.[0] && c?.[1]) deviation.value = c[0] / c[1] * 100
    })
    
    return {
      deviation,
      getDeviation
    }
  }
}
</script>

<style lang="scss">
.daily-indicator {
  * {
    flex-basis: 0;
    color: white;
    font-family: "Chakra Regular";
  }
  
  .rod {
    position: relative;
    display: flex;
    flex-grow: 1;
    
    &::before {
      content: '';
      
      position: absolute;
      bottom: 40px;
      
      width: 1px;
      height: 20px;
      
      background: #666;
    }
    
    &.longer {
      &::before {
        height: 27.5px;
      }
    }
    
    &.pointer {
      position: absolute;
      bottom: -2.5px;
      
      transition: all 1000ms;
      
      &::before {
        height: 30px;
        width: 3px;
        border-radius: 1px;
        background: #359a7e;
      }
    }
    
    
    span {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateX(-50%);
      color: #666;
    }
  }
}


</style>