<template>
  <div class="custom-bordered-element flex-grow-1 d-flex" :class="getClassList">
    <div v-if="corners || topLeft" class="custom-corner-top-left"/>
    <div v-if="corners || topRight" class="custom-corner-top-right"/>
    <div v-if="corners || bottomLeft" class="custom-corner-bottom-left"/>
    <div v-if="corners || bottomRight" class="custom-corner-bottom-right"/>
    <div v-if="borders || top" class="custom-border-top" :class="{ 'border-full-width' : fullWidth }"/>
    <div v-if="borders || right" class="custom-border-right" :class="{ 'border-full-width' : fullWidth }"/>
    <div v-if="borders || bottom" class="custom-border-bottom" :class="{ 'border-full-width' : fullWidth }"/>
    <div v-if="borders || left" class="custom-border-left" :class="{ 'border-full-width' : fullWidth }"/>
    <div class="p-3" :class="[{ 'flex-grow-1 d-flex flex-wrap' : !innerIsNotFlex }, getInnerClassList]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

export default {
  props: {
    innerIsNotFlex: {
      type: [Boolean],
      default: false,
    },
    disabled: {
      type: [Boolean],
      default: false,
    },
    hover: {
      type: [Boolean],
      default: false,
    },
    fullWidth: {
      type: [Boolean],
      default: false,
    },
    classes: {
      type: [Array],
      default: []
    },
    innerClasses: {
      type: [Array],
      default: []
    },
    corners: {
      type: [Boolean],
      default: false,
    },
    borders: {
      type: [Boolean],
      default: false,
    },
    topLeft: {
      type: [Boolean],
      default: false,
    },
    topRight: {
      type: [Boolean],
      default: false,
    },
    bottomLeft: {
      type: [Boolean],
      default: false,
    },
    bottomRight: {
      type: [Boolean],
      default: false,
    },
    top: {
      type: [Boolean],
      default: false,
    },
    right: {
      type: [Boolean],
      default: false,
    },
    bottom: {
      type: [Boolean],
      default: false,
    },
    left: {
      type: [Boolean],
      default: false,
    },
  },
  name: 'CustomBorderedElement',
  setup(props) {
    
    const getClassList = computed(() => {
      const list = [...props.classes]
      if (props.hover) list.push('custom-hover')
      if (props.disabled) list.push('not-allowed')
      
      return list.join(' ')
    })
    
    const getInnerClassList = computed(() => {
      const list = [...props.innerClasses]
      
      return list.join(' ')
    })
    
    return {
      getClassList,
      getInnerClassList,
    }
  }
}
</script>

<style lang="scss">
:root {
  --ts-corner-top-left-thickness: 2px;
  --ts-corner-top-left-length: 10px;
  
  --ts-corner-top-right-thickness: 2px;
  --ts-corner-top-right-length: 10px;
  
  --ts-corner-bottom-left-thickness: 2px;
  --ts-corner-bottom-left-length: 10px;
  
  --ts-corner-bottom-right-thickness: 2px;
  --ts-corner-bottom-right-length: 10px;
  
  --ts-border-top-thickness: 1px;
  --ts-border-right-thickness: 1px;
  --ts-border-bottom-thickness: 1px;
  --ts-border-left-thickness: 1px;
  
  --ts-border-color: #666;

}


.custom-bordered-element {

}


.custom-corner-top-left,
.custom-corner-top-right,
.custom-corner-bottom-left,
.custom-corner-bottom-right {
  pointer-events: none;
  
  position: absolute;
  width: 100%;
  height: 100%;
  
  &::before,
  &::after {
    content: '';
    background: white;
  
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.custom-corner-top-left {
  &::before,
  &::after {
     left: 0;
     top: 0;
   }
  
  &::before {
     width: var(--ts-corner-top-left-length);
     height: var(--ts-corner-top-left-thickness);
   }
  
  &::after {
     width: var(--ts-corner-top-left-thickness);
     height: var(--ts-corner-top-left-length);
   }
}
.custom-corner-top-right {
  &::before,
  &::after {
     right: 0;
     top: 0;
   }
  
  &::before {
     width: var(--ts-corner-top-right-length);
     height: var(--ts-corner-top-right-thickness);
   }
  
  &::after {
     width: var(--ts-corner-top-right-thickness);
     height: var(--ts-corner-top-right-length);
   }
}
.custom-corner-bottom-left {
  &::before,
  &::after {
     left: 0;
     bottom: 0;
   }
  
  &::before {
     width: var(--ts-corner-bottom-left-length);
     height: var(--ts-corner-bottom-left-thickness);
   }
  
  &::after {
     width: var(--ts-corner-bottom-left-thickness);
     height: var(--ts-corner-bottom-left-length);
   }
}
.custom-corner-bottom-right {
  &::before,
  &::after {
     right: 0;
     bottom: 0;
   }
  
  &::before {
     width: var(--ts-corner-bottom-right-length);
     height: var(--ts-corner-bottom-right-thickness);
   }
  
  &::after {
     width: var(--ts-corner-bottom-right-thickness);
     height: var(--ts-corner-bottom-right-length);
   }
}


.custom-border-top,
.custom-border-right,
.custom-border-bottom,
.custom-border-left {
  pointer-events: none;
  
  position: absolute;
  width: 100%;
  height: 100%;
  
  &::before,
  &::after {
    content: '';
  
    position: absolute;
  
    background: var(--ts-border-color);
    
  }
}

.custom-border-top {
  &::before {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    
    width: calc(100% - 30px);
    height: var(--ts-border-top-thickness);
  }
}
.custom-border-right {
  &::after {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    
    width: var(--ts-border-right-thickness);
    height: calc(100% - 30px);
  }
}
.custom-border-bottom {
  &::before {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    
    width: calc(100% - 30px);
    height: var(--ts-border-bottom-thickness);
  }
}
.custom-border-left {
  &::after {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  
    height: calc(100% - 30px);
    width: var(--ts-border-bottom-thickness);
  }
}

.border-full-width {
  &::before {
    width: 100% !important;
  }
  
  &::after {
    height: 100% !important;
  }
}


.custom-hover {
  transition: background 300ms;
  cursor: pointer;
  
  &:hover {
    background: rgba(255,255,255,0.2);
  }
}

.not-allowed {
  transition: background 300ms;
  cursor: not-allowed;
  
  &:hover {
    background: rgba(255,255,255,0.2);
  }
}
</style>