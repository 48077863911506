<template>
  <div class="distribution-chart row">
    <div class="col-2 py-4">
      <Doughnut
        id="chart-DistributionChart"
        :options="chartOptions"
        :data="chartData"
        :key="forceKey"
      />
    </div>
    <div class="col-10 text-uppercase" style="padding-left: 24px;">
      
      <div class="col-12" style="font-size: 14px;">cél</div>
      <div class="col-12" style="font-size: 18px;">{{ sum }} / {{ limit }} eur</div>
      <div class="col-12 stat-bar"></div>
      <div class="col-12 my-1" style="font-size: 10px;">{{ getDayName }}</div>
      <div class="col-12 d-flex clearfix" style="font-size: 12px;" v-for="i in [['gyártás', '#3cd2a5', values[0]], ['élezés', '#31816e', values[1]]]">
        <div class="flex-grow-1">
          <div class="colorball" :style="`background: ${i[1]}`"></div>
          {{ i[0] }}
        </div>
        <div class="flex-grow-1 text-end">{{ i[2] }} eur</div>
      </div>
      
    </div>
  </div>
</template>

<script>

import { onMounted, ref, watch, computed } from 'vue'

import { DateTime } from 'luxon'

import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js'

ChartJS.register(Tooltip, Legend, ArcElement, Colors)
export default {
  props: {
    values: {
      type: [Object, Array],
      default: [0, 0],
    },
    limit: {
      type: [Number],
      default: 0,
    }
  },
  name: "DistributionChart",
  components: { Doughnut },
  setup(props, context) {
    
    const forceKey = ref(0)
    
    const sum = ref(0)
  
    const chartOptions = ref({
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      responsive: true,
      cutout: '60%',
      plugins: {
        border: {
          display: false,
        },
        legend: false,
        datalabels: false,
      }
    })
    const chartData = ref({
      labels: ['GYÁRTÁS', 'ÉLEZÉS'],
      datasets: [
        {
          backgroundColor: ['#3cd2a5', '#31816e'],
          data: []
        }
      ]
    })
    
    const getDayName = computed(() => {
      return DateTime.now().weekdayLong
    })
    const sumPercentage = computed(() => {
      const percentage = sum.value/props.limit * 100
      
      if (percentage > 100) return 100 + '%'
      if (sum.value < 0) return 0 + '%'
      return percentage + '%'
    })
    
    watch(() => props.values, (v) => {
      const v0 = parseFloat(v[0])
      const v1 = parseFloat(v[1])
      
      sum.value = v0 + v1
      
      chartData.value.datasets[0].data = [v0/sum.value * 100, v1/sum.value * 100]
      
      forceKey.value++
    })
  
    return {
      getDayName,
      
      chartData,
      chartOptions,
      forceKey,
      
      sum,
      sumPercentage,
    }
  }
}
</script>

<style lang="scss">
.distribution-chart {
  
  * {
    flex-basis: initial !important;
  }
  
  display: flex;
  flex-grow: 1;
  
  .colorball {
    top: 1px;
    
    width: 12px;
    height: 12px;
    display: inline-block;
    background: red;
    
    border-radius: 3px;
  }
  
  
  .stat-bar {
    height: 8px;
    
    &::before {
      content: '';
      
      display: block;
      border-radius: 2px;
      
      width: 100%;
      height: 4px;
      
      position: absolute;
      top: 2px;
      left: 0;
      
      background: #475054;
    }
    
    &::after {
      content: '';
  
      display: block;
      border-radius: 2px;
      
      height: 4px;
  
      position: absolute;
      top: 2px;
      left: 0;
  
      transition: all 1000ms;
      
      width: v-bind('sumPercentage');
      background: #3cd2a5;
    }
  }
}
</style>