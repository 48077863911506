<template>
  <div class="col-12 settings-panel" :class="{ 'settings-active' : active }">
  
    <custom-bordered-element hover borders corners :classes="['d-block', 'width-is-max-content', 'float-start']" @click="close" style="position:absolute; left: 24px; top: 24px;">
      <div class="gear-icon" :style="'background-image: url(' + require('@/assets/image/gear.png') + ')'" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </custom-bordered-element>
  
    <custom-bordered-element hover borders corners :classes="['d-block', 'width-is-max-content', 'float-start', 'mx-2', 'text-uppercase']" style="position:absolute; left: 76px; top: 24px;" @click="saveSettings">
      beállítások mentése
    </custom-bordered-element>
  
    <custom-bordered-element borders corners inner-is-not-flex style="width: calc(100% - 48px); height: calc(90% - 24px); top: 10%;" :classes="['mx-auto']" :inner-classes="['col-12']">
      <div class="setting-row">
        Napi limit módosítás: <span style="font-weight: bold;">€</span><input v-model="settings.dailyLimit" style="outline: none; background: none; font-weight: bold; border: none; border-bottom: 1px solid white; width: 100px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button @click="project" title="Kivetítés hétre, hónapra 20 munkanappal." style="background: none; outline: none; border: 1px solid white;">Számítás</button>
      </div>
      <div class="setting-row">
        Heti limit módosítás: <span style="font-weight: bold;">€</span><input v-model="settings.weeklyLimit" style="outline: none; background: none; font-weight: bold; border: none; border-bottom: 1px solid white; width: 100px;">
      </div>
      <div class="setting-row">
        Havi limit módosítás: <span style="font-weight: bold;">€</span><input v-model="settings.monthlyLimit" style="outline: none; background: none; font-weight: bold; border: none; border-bottom: 1px solid white; width: 100px;">
      </div>
      <div class="setting-row">
        Éves limit módosítás: <span style="font-weight: bold;">€</span><input v-model="settings.yearlyLimit" style="outline: none; background: none; font-weight: bold; border: none; border-bottom: 1px solid white; width: 100px;">
      </div>
    </custom-bordered-element>
    
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { axios } from '@/main'

import CustomBorderedElement from '@/components/CustomBorderedElement.vue'

export default {
  components: { CustomBorderedElement },
  props: {
    active: {
      type: [Boolean],
      default: false,
    }
  },
  name: 'SettingsPanel',
  setup(props, context) {
  
    const settings = ref({})
    
    const close = () => {
      context.emit('closeHandle')
    }
    
    onMounted(() => {
      
      axios({}, 'api/settings.php?get-limits').then((r) => {
        settings.value = { ...settings.value, ...r.data }
      })
    })
    
    const project = () => {
      settings.value.weeklyLimit = settings.value.dailyLimit * 5
      settings.value.monthlyLimit = settings.value.weeklyLimit * 4
      settings.value.yearlyLimit = settings.value.monthlyLimit * 12
    }
  
    const saveSettings = () => {
      axios(settings.value, 'api/settings.php?save')
    }
    
    return {
      settings,
      close,
      saveSettings,
      project,
    }
  }
}
</script>

<style lang="scss">

  .settings-panel {
    overflow: hidden;
    transition: max-height 500ms;
    
    height: 100%;
    max-height: 0;
    
    position: fixed;
    left: 0;
    top: 0;
    
    z-index: 10;
    
    background: #273136;
  
    &.settings-active {
      max-height: 100%;
    }
    
    .setting-row {
      display: block;
      height: 40px;
    }
  }


</style>