<template>
	<router-view></router-view>
</template>

<script>

</script>

<style>

</style>
