<template>
  <div class="stat-view">
    <div class="stat-logo"/>
    <div class="color-bg"/>
    <div class="tool-bg" :style="'background-image: url(' + require('@/assets/image/tool_bg.png') + ')'"/>
    <div class="pattern-bg" :style="'background-image: url(' + require('@/assets/image/pattern_bg.png') + ')'"/>
    
    <div v-if="isLoggedIn">
      <settings-panel :active="settingsPanelIsActive" @close-handle="settingsPanelIsActive = false"/>
      
      <div class="col-12 float-start" style="margin-bottom: 24px;">
        <custom-bordered-element hover borders corners :classes="['d-block', 'width-is-max-content', 'float-start']"
                                 @click="settingsPanelIsActive = true">
          <div class="gear-icon" :style="'background-image: url(' + require('@/assets/image/gear.png') + ')'"/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </custom-bordered-element>
        
        <custom-bordered-element hover borders corners
                                 :classes="['d-block', 'width-is-max-content', 'float-start', 'mx-2', 'text-uppercase']"
                                 @click="getData">
          adatok frissítése
        </custom-bordered-element>
      </div>
      
      <div class="col-12 float-start">
        
        <div class="d-flex gap-2">
          
          <div class="flex-grow-1 d-flex flex-wrap">
            <custom-bordered-element borders corners>
              <div class="flex-full d-flex">
                <div class="flex-grow-1 text-uppercase">aktuális heti termelés</div>
                <div class="flex-grow-1 text-end text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(weekly) }} /
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(limits.weeklyLimit) }}
                </div>
              </div>
              
              <div class="flex-full d-flex">
                <daily-indicator :current="weekly" :limit="limits.weeklyLimit"/>
              </div>
            
            </custom-bordered-element>
            
            <div class="break"/>
            
            <div class="flex-grow-1 d-flex gap-2">
              <custom-bordered-element top bottom full-width>
                <div class="text-direction-vertical text-uppercase align-items-center text-center">prod</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">tegnapi zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(yesterday?.p?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="yesterday?.p?.[0] > yesterday?.p?.[1] ? 'chevron-up' : 'chevron-down'">&rsaquo;</span>előző
                  naphoz képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">heti zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(week?.p?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="week?.p?.[0] > week?.p?.[1] ? 'chevron-up' : 'chevron-downl'">&rsaquo;</span>előző
                  héthez képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">havi zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(month?.p?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="month?.p?.[0] > month?.p?.[1] ? 'chevron-up' : 'chevron-down'">&rsaquo;</span>előző
                  hónaphoz képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
            </div>
            
            <div class="break"/>
            
            <div class="flex-grow-1 d-flex gap-2">
              <custom-bordered-element top bottom full-width>
                <div class="text-direction-vertical text-uppercase align-items-center text-center">trade</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">tegnapi zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(yesterday?.t?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="yesterday?.t?.[0] > yesterday?.t?.[1] ? 'chevron-up' : 'chevron-down'">&rsaquo;</span>előző
                  naphoz képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">heti zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(week?.t?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="week?.t?.[0] > week?.t?.[1] ? 'chevron-up' : 'chevron-down'">&rsaquo;</span>előző
                  héthez képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">havi zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(month?.t?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="month?.t?.[0] > month?.t?.[1] ? 'chevron-up' : 'chevron-down'">&rsaquo;</span>előző
                  hónaphoz képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
            </div>
            
            <div class="break"/>
            
            <div class="flex-grow-1 d-flex gap-2">
              <custom-bordered-element top bottom full-width>
                <div class="text-direction-vertical text-uppercase align-items-center text-center">sum</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">tegnapi zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(yesterday?.s?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="yesterday?.s?.[0] > yesterday?.s?.[1] ? 'chevron-up' : 'chevron-down'">&rsaquo;</span>előző
                  naphoz képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">heti zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(week?.s?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="week?.s?.[0] > week?.s?.[1] ? 'chevron-up' : 'chevron-down'">&rsaquo;</span>előző
                  héthez képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
              <custom-bordered-element borders corners :classes="['flex-grow-4']">
                <div class="flex-full text-uppercase">havi zárás</div>
                <div class="flex-full text-uppercase eur-stat-text">
                  €{{ new Intl.NumberFormat('hu-HU', { currency: 'EUR' }).format(month?.s?.[0]) }}
                </div>
                <div class="flex-full prev-stat-text"><span class="chevron"
                                                            :class="month?.s?.[0] > month?.s?.[1] ? 'chevron-up' : 'chevron-down'">&rsaquo;</span>előző
                  hónaphoz képest
                </div>
                <div class="flex-full prev-stat-text">havi átlaghoz képest</div>
                <div class="flex-full prev-stat-text">éves átlaghoz képest</div>
              </custom-bordered-element>
            </div>
          </div>
          
          <div class="flex-grow-1 d-flex">
            <custom-bordered-element borders corners>
              <div class="flex-full d-flex text-uppercase">
                Napi termelés
              </div>
              <div class="flex-full d-flex">
                <weekly-total-daily-euro-sum :values="chart1"/>
              </div>
              <div class="flex-full d-flex">
                <distribution-chart :limit="limits.dailyLimit" :values="[daily, 0]"/>
              </div>
            </custom-bordered-element>
          </div>
          
          <div class="flex-grow-1 d-flex log-window">
            <custom-bordered-element borders corners>
              <div class="flex-full d-flex text-uppercase">
                Log
              </div>
              
              <div class="overflown-element p-3">
                <div class="col-12 py-1 px-3 float-start log-item" v-for="l in logs">
                  <div v-if="l.type === 1">
                    Új rendelés: {{ l.order }} {{ l.remark }}
                    <div class="float-end">{{ l.timestamp.slice(0, -3) }}</div>
                  </div>
                  <div v-if="l.type === 2">
                    {{ l.machine }} Elkészült {{ l.finished }}db: {{ l.order }} {{ l.remark }}
                    <div class="float-end">{{ l.timestamp.slice(0, -3) }}</div>
                  </div>
                  <div v-if="l.type === 3">
                    {{ l.machine }} Visszavonva {{ l.finished }}db: {{ l.order }} {{ l.remark }}
                    <div class="float-end">{{ l.timestamp.slice(0, -3) }}</div>
                  </div>
                  <div v-if="l.type === 4">
                    Új trade: €{{ l.price }} {{ l.invoice }}
                    <div class="float-end">{{ l.timestamp.slice(0, -3) }}</div>
                  </div>
                </div>
              </div>
            
            
            </custom-bordered-element>
          </div>
        
        </div>
        
        <div class="break"/>
        
        <div class="d-flex gap-2">
          <custom-bordered-element borders corners :classes="['flex-grow-2']">
            <div class="flex-full d-flex text-uppercase">
              heti termelés
            </div>
            <div class="flex-full d-flex whole-year">
              <yearly-total-machine-weekly-euro-sum :values="chart2"/>
            </div>
            
            <div class="break"/>
            
            <custom-bordered-element>
              <div class="flex-full d-flex text-uppercase justify-content-center">
                heti termelési cél
              </div>
              <div class="flex-full d-flex">
                <distribution-chart :limit="limits.weeklyLimit" :values="[weekly, 0]"/>
              </div>
            </custom-bordered-element>
            
            <custom-bordered-element>
              <div class="flex-full d-flex text-uppercase justify-content-center">
                havi termelési cél
              </div>
              <div class="flex-full d-flex">
                <distribution-chart :limit="limits.monthlyLimit" :values="[monthly, 0]"/>
              </div>
            </custom-bordered-element>
            
            <custom-bordered-element>
              <div class="flex-full d-flex text-uppercase justify-content-center">
                éves termelési cél
              </div>
              <div class="flex-full d-flex">
                <distribution-chart :limit="limits.yearlyLimit" :values="[yearly, 0]"/>
              </div>
            </custom-bordered-element>
          
          </custom-bordered-element>
        </div>
        
        <div class="break"/>
        
        
        <div class="d-flex gap-2">
          <custom-bordered-element borders corners :classes="['flex-grow-2']">
            <div class="flex-full d-flex text-uppercase">
              havi termelés
            </div>
            <div class="flex-full d-flex whole-year">
              <yearly-total-machine-monthly-euro-sum :values="chart1356431"/>
            </div>
          </custom-bordered-element>
        </div>
        
        
        <div class="d-flex gap-2">
          
          <!--        <custom-bordered-element borders corners>-->
          <!--          <div style="min-height: 300px; pointer-events: none;" />-->
          <!--          <div class="flex-full d-flex text-uppercase">-->
          <!--            Bevonatra vár-->
          <!--          </div>-->
          
          <!--          <div class="overflown-element p-3">-->
          <!--            <div class="col-12 py-1 px-3 float-start log-item" v-for="i in 200">-->
          <!--              {{ i }}-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </custom-bordered-element>-->
          
          <!--        <custom-bordered-element borders corners>-->
          <!--          <div class="flex-full d-flex text-uppercase">-->
          <!--            Bevonaton-->
          <!--          </div>-->
          
          <!--          <div class="overflown-element p-3">-->
          <!--            <div class="col-12 py-1 px-3 float-start log-item" v-for="i in 200">-->
          <!--              {{ i }}-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </custom-bordered-element>-->
          
          <!--        <custom-bordered-element borders corners>-->
          <!--          <div class="flex-full d-flex text-uppercase">-->
          <!--            Kiszállításra vár-->
          <!--          </div>-->
          
          <!--          <div class="overflown-element p-3">-->
          <!--            <div class="col-12 py-1 px-3 float-start log-item" v-for="i in 200">-->
          <!--              {{ i }}-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </custom-bordered-element>-->
          
          <!--        <custom-bordered-element borders corners>-->
          <!--          <div class="flex-full d-flex text-uppercase">-->
          <!--            Kiszállítva-->
          <!--          </div>-->
          
          <!--          <div class="overflown-element p-3">-->
          <!--            <div class="col-12 py-1 px-3 float-start log-item" v-for="i in 200">-->
          <!--              {{ i }}-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </custom-bordered-element>-->
          
          
          <!--    <custom-bordered-element borders corners>-->
          <!--      <table class="unstyledTable col-12">-->
          <!--        <tr>-->
          <!--          <td>2023</td>-->
          <!--          <td>Darab</td>-->
          <!--          <td>Érték</td>-->
          <!--        </tr>-->
          <!--        <tr v-if="chart3 != null">-->
          <!--          <td>Ebben az évben</td>-->
          <!--          <td>{{ chart3.datasets[0].data[0].toLocaleString() }} db</td>-->
          <!--          <td>{{ chart3.datasets[0].data[1].toLocaleString() }} €</td>-->
          <!--        </tr>-->
          <!--        <tr v-if="chart4 != null">-->
          <!--          <td>Ebben a hónapban</td>-->
          <!--          <td>{{ chart4.datasets[0].data[0].toLocaleString() }} db</td>-->
          <!--          <td>{{ chart4.datasets[0].data[1].toLocaleString() }} €</td>-->
          <!--        </tr>-->
          <!--        <tr v-if="chart5 != null">-->
          <!--          <td>Ezen a héten</td>-->
          <!--          <td>{{ chart5.datasets[0].data[0].toLocaleString() }} db</td>-->
          <!--          <td>{{ chart5.datasets[0].data[1].toLocaleString() }} €</td>-->
          <!--        </tr>-->
          <!--      </table>-->
          <!--    </custom-bordered-element>-->
          
          
          <!--
            <div class="float-start col-12 chart-container" style="min-height: 2.5vh;">
              <year-sum-to-this-day :values="chart3" />
            </div>
            <div class="float-start col-12 chart-container" style="min-height: 2.5vh;">
              <month-sum-to-this-day :values="chart4" />
            </div>
            <div class="float-start col-12 chart-container" style="min-height: 2.5vh;">
              <week-sum-to-this-day :values="chart5" />
            </div>
          -->
        </div>
      </div>
    </div>
    <div v-else style="min-height: 80vh;">
      <div style="position: absolute; top: 40%; left: 50%; transform: translate(-50%, -50%);">
        <custom-bordered-element borders corners :classes="['d-block', 'width-is-max-content']">
          <input v-model="password" type="password" class=" text-center"
                 style="flex-grow: 1; background: none; border: 1px solid gray; outline: none;" @keypress.enter="logIn">
          <div class="break"/>
          <div class="break"/>
          <button class="mx-auto" @click="logIn" style="color: black;">Belépés</button>
        </custom-bordered-element>
      </div>
    </div>
  </div>
</template>

<script>

import { onMounted, ref, watch } from 'vue'

import { axios } from '@/main'
import { DateTime } from 'luxon'

import WeeklyTotalDailyEuroSum from '@/components/charts/WeeklyTotalDailyEuroSum.vue'
import YearlyTotalMachineWeeklyEuroSum from '@/components/charts/YearlyTotalMachineWeeklyEuroSum.vue'
import YearlyTotalMachineMonthlyEuroSum from '@/components/charts/YearlyTotalMachineMonthlyEuroSum.vue'
import YearSumToThisDay from '@/components/charts/YearSumToThisDay.vue'
import MonthSumToThisDay from '@/components/charts/MonthSumToThisDay.vue'
import WeekSumToThisDay from '@/components/charts/WeekSumToThisDay.vue'
import CustomBorderedElement from '@/components/CustomBorderedElement.vue'
import DailyIndicator from '@/components/charts/DailyIndicator.vue'
import SettingsPanel from '@/components/charts/SettingsPanel.vue'
import DistributionChart from '@/components/charts/DistributionChart.vue'

export default {
  name: 'Statistics',
  components: {
    YearlyTotalMachineMonthlyEuroSum,
    DistributionChart,
    SettingsPanel,
    DailyIndicator,
    CustomBorderedElement,
    WeekSumToThisDay,
    MonthSumToThisDay,
    YearSumToThisDay,
    YearlyTotalMachineWeeklyEuroSum,
    WeeklyTotalDailyEuroSum,
  },
  setup(props, context)
  {
    const settingsPanelIsActive = ref(false)
    
    const password = ref(null)
    const isLoggedIn = ref(false)
    
    const logs = ref([])
    const limits = ref({})
    
    const chart1 = ref(null)
    const chart2 = ref(null)
    const chart3 = ref(null)
    const chart4 = ref(null)
    const chart5 = ref(null)
    
    const chart1356431 = ref(null)
    
    const daily = ref(0)
    const weekly = ref(0)
    const monthly = ref(0)
    const yearly = ref(0)
    
    const yesterday = ref({})
    const week = ref({})
    const month = ref({})
    
    const getData = () => {
      
      axios({}, '/api/log.php?get-all').then((response) => {
        logs.value = response.data
      })
      
      axios({}, 'api/settings.php?get-limits').then((r) => {
        limits.value = r.data
      })
      
      
      axios({
        year: DateTime.now().year,
        week: DateTime.now().weekNumber,
      }, '/api/statistics.php?daily-sum-of-week').then((response) => {
        chart1.value = response.data
      })
      
      axios({ year: DateTime.now().year }, '/api/statistics.php?weekly-sum-of-year').then((response) => {
        chart2.value = response.data
      })
      
      axios({ year: DateTime.now().year }, '/api/statistics.php?monthly-sum-of-year').then((response) => {
        console.log(response.data)
        chart1356431.value = response.data
      })
      
      axios({}, '/api/statistics.php?year-sum-to-this-day').then((response) => {
        chart3.value = response.data
      })
      
      axios({}, '/api/statistics.php?month-sum-to-this-day').then((response) => {
        chart4.value = response.data
      })
      
      axios({}, '/api/statistics.php?week-sum-to-this-day').then((response) => {
        chart5.value = response.data
      })
      
      axios({}, 'api/statistics.php?daily').then((r) => {
        daily.value = r.data.toFixed(0)
      })
      axios({}, 'api/statistics.php?weekly').then((r) => {
        weekly.value = r.data.toFixed(0)
      })
      axios({}, 'api/statistics.php?monthly').then((r) => {
        monthly.value = r.data.toFixed(0)
      })
      axios({}, 'api/statistics.php?yearly').then((r) => {
        yearly.value = r.data.toFixed(0)
      })
      
      
      axios({}, 'api/statistics.php?yesterday').then((r) => {
        yesterday.value = r.data
      })
      axios({}, 'api/statistics.php?week').then((r) => {
        week.value = r.data
      })
      axios({}, 'api/statistics.php?month').then((r) => {
        month.value = r.data
      })
      
    }
    
    watch(isLoggedIn, (val) => {
      if (val) getData()
    })
    
    const logIn = () => {
      axios({ password: password.value }, 'api/user.php?user-state').then((r) => {
        if (r.data === 1) isLoggedIn.value = true
      })
    }
    
    return {
      settingsPanelIsActive,
      
      password,
      isLoggedIn,
      logIn,
      
      logs,
      
      chart1,
      chart2,
      chart3,
      chart4,
      chart5,
      chart1356431,
      
      daily,
      weekly,
      monthly,
      yearly,
      
      yesterday,
      week,
      month,
      
      limits,
      getData,
    }
    
  },
}
</script>

<style lang="scss">
body {
  padding: 24px;
}

.stat-view {
  
  * {
    flex-basis: 0;
    color: white;
    font-family: "Chakra Regular";
  }
  
  .stat-logo {
    width: 200px;
    height: 60px;
    
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: url(https://tstools.hu/wp-content/uploads/2021/02/ts_tools_logo_full_colored_shadow.png?fbclid=IwAR0fkuQfbISUdhHGlqeJwgmQAy5bBYWhQWnZGhv4C2MumAYA1VCUY330gWc);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .whole-year {
    min-height: 500px;
  }
  
  .prev-stat-text {
    padding-left: 16px;
    font-size: 11px;
  }
  
  .eur-stat-text {
    font-size: 24px;
  }
  
  .gear-icon {
    position: absolute;
    top: 0;
    left: 0;
    
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    
    filter: invert(1);
  }
  
  .log-window {
  
  }
  
  .log-item {
    
    height: 30px;
    white-space: nowrap;
    
    &:nth-child(even) {
      background: rgba(255, 255, 255, 0.25);
    }
  }
  
  
  .color-bg,
  .tool-bg,
  .pattern-bg {
    position: fixed;
    left: 0;
    top: 0;
    
    width: 100%;
    height: 100%;
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
    pointer-events: none;
  }
  
  .tool-bg {
    opacity: 5%;
  }
  
  .color-bg {
    z-index: -1;
    background: #273136;
  }
  
  .pattern-bg {
    background-size: 7.5%;
    background-repeat: repeat;
    opacity: 5%;
  }
  
  table td {
    border: 1px dashed gray;
    padding: 7px 14px;
    
    &:nth-child(1n+2) {
      min-width: 200px;
    }
  }
  
  .overflown-element {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(calc(100% - 48px) - 1rem);
    transform: translateY(48px);
    overflow-y: scroll;
  }
  
  .chevron {
    position: absolute;
    left: 0;
    top: -9px;
    font-size: 24px;
    display: inline-block;
    text-align: center;
    
    &.chevron-up {
      transform: rotate(-90deg) !important;
    }
    
    &.chevron-down {
      transform: rotate(90deg) !important;
    }
  }
}
</style>
